import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Dynamic imports
const ArticleCards = dynamic(() => import('./Cards/ArticleCards'));
const DealCards = dynamic(() => import('./Cards/DealsCards'));
const ContractCards = dynamic(() => import('./Cards/ContractCards'));

const renderCards = (item, location) => {
  switch (item.card_type) {
    case 'stories':
      return <ArticleCards item={item} location={location} />;
    case 'deal':
      return <DealCards {...item} />;
    case 'solution':
      return <DealCards {...item} />;
    case 'contract':
      return <ContractCards {...item} />;
    default:
      return <ArticleCards item={item} location={location} />;
  }
};

const MasonryLayout = ({ item, location }) => {
  return (
    <div>
      {renderCards(item, location)}
    </div>
  );
};

MasonryLayout.propTypes = {
  item: PropTypes.object,
  location: PropTypes.string,
};

export default MasonryLayout;
